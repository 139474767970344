<template>
  <img alt="Vue logo" v-bind:src="logoUrl">
  <h1 v-if="!hideBranding">Brendan Kristiansen Photo Delivery</h1> <br>

  <div v-if="!loggedIn">
    <p>
      Password:<br>
      <input type="password" v-model="passwd"><br>
      <span class="badge bg-danger" v-if="loginFailed">Incorrect Password</span>
      <br>
      <button class="btn btn-dark" @click="attemptLogin" data-umami-event="Login Button">Login</button>
    </p>
  </div>

  <div v-else>
    <h2>{{clientName}}</h2>

    <div v-for="download in downloadList" v-bind:key="download.eventName">
      <div class="card" style="width: 18rem;">
        <div class="card-body">
          <h5 class="card-title">{{download.eventName}}</h5>
          <h6 class="card-subtitle mb-2 text-muted">{{download.eventDate}}</h6>
          <p class="card-text">{{download.desc}}</p>

          <p  v-if="download.galleryUrl !== ''">
            <a type="button" class="btn btn-outline-success" target="_blank"
               v-bind:href="download.galleryUrl" data-umami-event="Go to gallery" style="margin-bottom: 10px">
              View Photos
            </a>
          </p>

          <label class="text-dark-emphasis">Downloads (Desktop only)</label><br>
          <div class="btn-group-sm" role="group" aria-label="Basic outlined example">
            <a type="button" class="btn btn-outline-primary btn-sm" v-if="download.printUrl !== ''" v-bind:href="download.printUrl" data-umami-event="Download picks">Printable</a>
            <a type="button" class="btn btn-outline-primary btn-sm" v-if="download.fullUrl !== ''" v-bind:href="download.fullUrl" data-umami-event="Download full take">Full Take</a>
            <a type="button" class="btn btn-outline-primary btn-sm" v-if="download.archiveUrl !== ''" v-bind:href="download.archiveUrl" data-umami-event="Download RAW">RAW Archive</a>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <button class="btn btn-danger" @click="logout" data-umami-event="Logout Button">Exit</button><br>
  </div><br>

  <div class="border-bottom" v-if="apiHit">
    Connected: {{apiVer}}
  </div>

</template>

<script>

import axios from "axios";

export default {
  name: 'App',
  components: {
  },

  data(){
    return{
      apiHit: false,
      apiVer: '',

      loggedIn: false,
      loginFailed: false,
      passwd: '',

      clientName: 'test client',
      logoUrl: 'https://downloadmedia.bek.sh/photo-delivery/client-logos/bk.png',
      isAdmin: false,
      hideBranding: false,

      downloadList:[
      ]
    }
  },

  methods:{

    checkApi: async function (){
      this.apiHit = true;
      this.apiVer = "0.1.0";

      var resp = await axios.get('/api');

      if (resp.status === 200) {
        this.apiVer = resp.data['version'];
        this.apiHit = true;
      }else{
        this.apiHit = false;
      }
    },

    attemptLogin: async function (){
      // if (this.passwd.length > 0) this.loggedIn = true;
      // else this.loginFailed = true;

      let payload = {password: this.passwd};

      var fail = false;
      var resp = await axios.post('/api/auth', payload).catch(
          function (error) {
            if (error.response){
              console.log(error.response);
              fail = true;
            }
          }
      );

      if (fail) this.loginFailed = true;
      else{
        if (resp.status === 200) {

          if (!resp.data.accepted){
            this.loginFailed = true;
            return;
          }

          console.log(resp.data);
          this.loggedIn = true;

          let cfg = resp.data.config;
          if (cfg.logo !== null) this.logoUrl = cfg.logo;
          if (cfg.hide_branding !== null) this.hideBranding = cfg.hide_branding;
          if (cfg.admin !== null) this.isAdmin = cfg.admin;

          this.clientName = resp.data.client;
          if (resp.data.events.length > 0){
            for (var i = 0; i < resp.data.events.length; i++){
              this.downloadList.push(resp.data.events[i]);
            }
          }else{
            // todo: make this appear on the site
            console.log("You have no events")
          }

          console.log(this.downloadList);

        }else{
          this.loginFailed = true;
        }
      }
    },

    logout: function () {
      this.loggedIn = false;
      this.loginFailed = false;
      this.passwd = false;
      this.downloadList = [];
      this.clientName = '';
      this.hideBranding = false;
      this.isAdmin = false;
      this.logoUrl = 'https://downloadmedia.bek.sh/photo-delivery/client-logos/bk.png';
    }
  },

  mounted() {
    this.checkApi();
  }
}
</script>

<style>

@import "~bootstrap";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>
